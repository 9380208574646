import React, { useEffect } from "react";
import { useState } from "react";
import AnnouncementsFilter from "../../announcements-filter/AnnouncementsFilter";
import { FaFileCircleCheck } from "react-icons/fa6";
import axios from "axios";
import ElementCard from "../../element-card/ElementCard";
import Loader from "../../loader/Loader";

const MyAnnouncements = ({ userData }) => {
  const [ann, setAnn] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [annImages, setAnnImages] = useState([]);
  const [ann2, setAnn2] = useState([])
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    const get_items = async () => {
      try {
        const { data } = await axios.get(
          "https://service.homely.am/api/items/all"
        );
        setAnn(data.all_items.reverse());
        
        setAnnImages(() => data.all_images);
      } catch (err) {
        console.log(err);
      }
    };
    get_items();
  }, []);

  return (
    <div className="workers-page">
      
      <div style={{ height: "100px" }}></div>
      <p
        style={{
          textAlign: "center",
          color: "#fff",
          fontSize: "18px",
          background: "#202137",
          paddingBottom: "15px",
          paddingTop: "15px"
        }}
      >
        Միայն Ձեր հայտարարությունները
      </p>
      <AnnouncementsFilter onFiltration={(data)=> {
        console.log(data.regions)
        setAnn2(() => ann.filter(el => {
          if (data.id && Number(data.id) !== el.id) return false;
          if (data.owner_phone && Number(data.owner_phone) !== Number(el.owner_phone)) return false;
          // if (data.address.length && !el.street.startsWith(data.address)) return false;
          if (data.regions.length && !data.regions.includes(el.region)) return false;
          if (data.rent_or_sell && el.type !== data.rent_or_sell) return false;
          if (data.roomsNumber.length && !data.roomsNumber.includes(el.number_of_rooms)) return false;
          if ((data.status != undefined && data.status != []) && !data.status.includes(el.status)) return false;
          if ((data.building_type != undefined && data.building_type != []) && !data.building_type.includes(el.type_of_building)) return false;
          if (data.floor_min &&  (Number(el.floor) < Number(data.floor_min))) return false;
          if (data.floor_max &&  (Number(el.floor) > Number(data.floor_max))) return false;
          if (data.price_from && Number(el.price) < Number(data.price_from)) return false;
          if (data.price_to && Number(el.price) > Number(data.price_to)) return false;
          return el
        }))
      }}/>
      <div className="all_ann">
        {
          loader ? <Loader /> : (
              ann2.length ? (
                ann2.map((el) => {
                  const itemIMAGES = annImages.filter((e) => e.item_id == el.id);
                  const brokerData = brokers.filter((e) => e.id === Number(el.adminid));
                  return (
                    <div className="el_data_card">
                      <ElementCard
                        price={el.price}
                        id={el.id}
                        img={
                          itemIMAGES[0] &&
                          `https://service.homely.am/storage/images/${itemIMAGES[0].image}`
                        }
                        title={el.title}
                        status={null}
                        area={el.area}
                        bathrooms={el.number_of_bathrooms}
                        rooms={el.number_of_rooms}
                        number_of_floors={el.number_of_floors}
                        floors={el.floor}
                        item_status={el.item_status}
                        hidden_des={el.description_hidden}
                        admin_id={el.adminid}
                        user_id={userData.id}
                        user_type={userData.type}
                        all={el}
                      />
                      <div className="broker_owner_info">
                        {(userData.type !== "broker" ||
                          Number(el.adminid) === Number(userData.id)) && (
                          <>
                            <b>Գույքի տեր</b>
                            <div className="owner_data">
                              <span>{el.owner_name}</span>
                              <b>{el.owner_phone}</b>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                ann.filter(E => E.adminid == userData.id).map((el) => {
                  const itemIMAGES = annImages.filter((e) => e.item_id == el.id);
                  const brokerData = brokers.filter((e) => e.id === Number(el.adminid));
                  return (
                    <div className="el_data_card">
                      <ElementCard
                        price={el.price}
                        id={el.id}
                        img={
                          itemIMAGES[0] &&
                          `https://service.homely.am/storage/images/${itemIMAGES[0].image}`
                        }
                        title={el.title}
                        status={null}
                        area={el.area}
                        bathrooms={el.number_of_bathrooms}
                        rooms={el.number_of_rooms}
                        number_of_floors={el.number_of_floors}
                        floors={el.floor}
                        item_status={el.item_status}
                        hidden_des={el.description_hidden}
                        admin_id={el.adminid}
                        user_id={userData.id}
                        user_type={userData.type}
                        all={el}
                      />
                      <div className="broker_owner_info">
                        {(userData.type !== "broker" ||
                          Number(el.adminid) === Number(userData.id)) && (
                          <>
                            <b>Գույքի տեր</b>
                            <div className="owner_data">
                              <span>{el.owner_name}</span>
                              <b>{el.owner_phone}</b>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })
              )
            
          )
        }
      </div>
    </div>
  );
};

export default MyAnnouncements;
